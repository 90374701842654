import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class EntityDTO
{
    id: number;
    name: string;
}

export default class Entity extends AbstractBaseEntity
{
    private id: number;
    private name: string;

    constructor(id: number, name: string)
    {
        super();
        this.id = id;
        this.name = name;
    }

    static create(dto: EntityDTO): Entity
    {
        return new Entity(dto.id, dto.name);
    }
}